'use client';

import CustomButton from '../CustomButton';
import CustomGrid from '../CustomGrid';
import CustomImage from '../CustomImage';
import SectionWrapper from '../SectionWrapper';
import MediaContainer from '../MediaContainer';
import VehicleClassifierPopup from '../vehicle-classifier/VehicleClassifierPopup';

export default function CtaSection({
  id = '',
  content = {
    heading: '',
    subheading: '',
    paragraph: '',
    blockText: '',
  },
  button = {
    label: '',
    href: '',
    icon: '',
  },
  mediaProps = {
    // Renamed from MediaContainer to mediaProps
    padding: '',
    containerClass: '',
    aspectRatio: 'aspect-square, md:aspect-video, lg:aspect-square',
  },
  image = {
    src: '',
    alt: 'Mayes Auto Detailing Service Image',
    position: 'right',
    objectFit: 'cover',
  },
  usePopup = false, // New prop to determine which button to show
  popupButton = {
    title: 'Find your vehicle size',
    variant: 'primary',
  },
}) {
  const hasImage = Boolean(image.src);
  const hasButton = Boolean(button.label) || usePopup;
  const hasSubheading = Boolean(content.subheading);
  const hasBlockText = Boolean(content.blockText);

  return (
    <SectionWrapper id={id}>
      {/* Responsive Grid (Text Centers Fully if No Image) */}
      <CustomGrid
        variant={!hasImage ? 'single' : 'default'}
        className="bg-mad-blue relative overflow-hidden rounded-sm shadow-lg"
      >
        {/* SVG Circle Positioned Between Background & Content */}
        <div className="absolute inset-0 z-0 flex items-center justify-center">
          <svg
            viewBox="0 0 1024 1024"
            className="absolute bottom-0 left-1/2 -z-10 size-[56rem] -translate-x-1/2"
            aria-hidden="true"
          >
            <circle
              r={512}
              cx={512}
              cy={512}
              fill="url(#mad-gradient)"
              fillOpacity="0.7"
            />
            <defs>
              <radialGradient id="mad-gradient">
                <stop stopColor="#ce2034" />
                <stop offset={1} stopColor="#0a1f42" />
              </radialGradient>
            </defs>
          </svg>
        </div>

        {/* Image Section (Left) */}
        {hasImage && image.position === 'left' && (
          <MediaContainer
            containerClass={`z-10 ${mediaProps.containerClass}`}
            padding={mediaProps.padding}
            aspectRatio={mediaProps.aspectRatio}
          >
            <CustomImage
              src={image.src}
              alt={image.alt}
              objectFit={image.objectFit}
            />
          </MediaContainer>
        )}

        {/* Text Section (Fully Centered If No Image) */}
        <div
          className={`relative z-10 mx-auto flex flex-col space-y-6 p-6 md:p-12 ${
            hasImage
              ? 'justify-center text-center lg:text-left'
              : 'flex w-full items-center justify-center text-center'
          } `}
        >
          {hasSubheading && (
            <h3 className="text-mad-white text-lg font-semibold uppercase">
              {content.subheading}
            </h3>
          )}
          <h2 className="text-mad-white text-3xl font-semibold tracking-tight uppercase sm:text-4xl">
            {content.heading}
          </h2>
          {hasBlockText && (
            <div className="text-mad-blue mt-6 rounded-lg bg-white p-4 font-bold shadow-md md:p-6">
              <p className="text-4xl">{content.blockText}</p>
            </div>
          )}
          {content.paragraph && (
            <p className="text-mad-white/80 text-lg">{content.paragraph}</p>
          )}

          {/* Conditionally Render Button or Popup */}
          {hasButton && (
            <div className="mt-10 flex justify-center gap-x-6 lg:justify-start">
              {usePopup ? (
                <VehicleClassifierPopup
                  buttonTitle={popupButton.title}
                  buttonVariant={popupButton.variant}
                />
              ) : (
                <CustomButton
                  variant={button.buttonVariant}
                  href={button.href}
                  iconName={button.icon}
                  className="uppercase"
                >
                  {button.label}
                </CustomButton>
              )}
            </div>
          )}
        </div>

        {/* Image Section (Right) */}
        {hasImage && image.position === 'right' && (
          <MediaContainer
            containerClass={`z-10 ${image.containerClass}`}
            padding={mediaProps.padding}
            aspectRatio={mediaProps.aspectRatio}
          >
            <CustomImage src={image.src} alt={image.alt} />
          </MediaContainer>
        )}
      </CustomGrid>
    </SectionWrapper>
  );
}
