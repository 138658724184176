import React from 'react';

export default function CustomGrid({
  children,
  variant = 'default',
  className = '',
}) {
  // Base grid styles common to all grid variants
  const baseStyles = 'grid w-full gap-4';

  // Define variant-specific grid classes
  const variantStyles = {
    default: 'grid-cols-1 lg:grid-cols-2',
    single: 'grid-cols-1',
    double: 'grid-cols-2',
    doubleVertical: 'grid-row-2',
    two: 'grid-col-1 md:grid-cols-2',
    three: 'grid-cols-1 md:grid-cols-3',
    four: 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-4',
    quad: 'grid-cols-2 lg:grid-cols-4',
    twoXTwo: 'grid-cols-1 md:grid-cols-2 lg:grid-cols-1',
  };

  // Combine base styles, variant-specific styles, and any additional classes
  const gridClasses = `${baseStyles} ${variantStyles[variant] || variantStyles['default']} ${className}`;

  return <div className={gridClasses}>{children}</div>;
}
